// This file be kept in sync with the billingUtils.ts file in the static package

import { schemas } from '@gonfalon/openapi';

// state and planType in the BE are enums but in the API response its string representation is returned
export type ModernSubscription = Omit<schemas['SubscriptionRep'], 'state' | 'planType' | '_limits'> & {
  state?: string;
  planType?: PlanTypes;
  _limits?: { [k in LimitNames]?: number | null };
  _next?: ScheduledSubscriptionChange | null;
};
export type LegacySubscription = schemas['Subscription'];

// union type for all subscription types
export type Subscription = ModernSubscription | LegacySubscription;

export type LimitPlanList = Omit<schemas['LimitPlanListRep'], 'plans'> & { plans?: { [k in LimitNames]?: LimitPlan } };

export type Tier = schemas['TierRep'];
export type LimitPlan = schemas['LimitPlanRep'];

export type SubscriptionUsage = Omit<schemas['SubscriptionUsageRep'], '_usage'> &
  { _usage?: { [k in LimitNames]?: number } }['_usage'];

// Once the SubscriptionRep has the _next field returning from the spec, update this
export type ScheduledSubscriptionChange = {
  effectiveDate: string;
  updatedAt: string;
  primaryContext: string;
  limits: { [k in LimitNames]: number };
};

export enum SubscriptionStates {
  ACTIVE_TRIAL = 'ActiveTrial',
  ACTIVE_TRIAL_WITH_SUBSCRIPTION = 'ActiveTrialWithSubscription',
  GRACE_PERIOD_TRIAL = 'GracePeriodTrial',
  LAPSED_TRIAL = 'LapsedTrial',
  ACTIVE_SUBSCRIPTION = 'ActiveSubscription',
  FAILED_PAYMENT_SUBSCRIPTION = 'FailedPaymentSubscription',
  PENDING_CANCELED_SUBSCRIPTION = 'PendingCanceledSubscription',
  CANCELED_SUBSCRIPTION = 'CanceledSubscription',
  LAPSED_SUBSCRIPTION = 'LapsedSubscription',
  UNKNOWN = '**Immutable.js Record default only**',
}

export const enum PlanTypes {
  LEGACY = 'Legacy',
  STARTER = 'Starter',
  STARTER2021 = 'Starter2021',
  STANDARD_TRIAL = 'StandardTrial',
  STANDARD_TRIAL2021 = 'StandardTrial2021',
  PROFESSIONAL = 'Professional',
  PROFESSIONAL2021 = 'Professional2021',
  ENTERPRISE_TRIAL = 'EnterpriseTrial',
  ENTERPRISE = 'Enterprise',
  ENTERPRISE2023 = 'Enterprise2023',
  FOUNDATION2023 = 'Foundation2023',
  FOUNDATION_TRIAL2023 = 'FoundationTrial2023',
  PARTNER = 'Partner',
  DEVELOPER = 'Developer',
  GUARDIAN_TRIAL2024 = 'GuardianTrial2024',
  GUARDIAN2024 = 'Guardian2024',
  GUARDIAN2024S = 'Guardian2024S',
}

// This is the billing process for the plan
// copied from billingUtils.ts - we need to consolidate the two
export enum BillingProcess {
  STANDARD = 'StandardBilling',
  USAGE_BASED_BILLING = 'UsageBasedBilling', // monthly usage based plans
  COMMIT_WITH_OVERAGE = 'CommitWithOverageBilling', // annual usage based plans
}

export const enum LimitNames {
  SEATS = 'SeatCount',
  EVENTS_PUBLISHED = 'EventsPublished',
  EVENTS_RECEIVED = 'EventsReceived',
  SSO = 'SSO',
  EXPERIMENTATION_KEYS = 'ExperimentationKeys',
  MONTHLY_ACTIVE_USERS = 'MonthlyActiveUsers', // Contexts
  HOSTS = 'Hosts', // Service Connections
}

export type MainCancelReason =
  | 'time'
  | 'getting-started'
  | 'capabilities'
  | 'team-adoption'
  | 'tool'
  | 'price'
  | 'other';

export type CancelFormFields = { mainCancelReason?: MainCancelReason; customCancelReason?: string };

export type BillingEventInfo = {
  daysLeftInTrial?: number;
  error?: string;
  trialBannerVariation?: string;
  termsVersion?: string;
} & CancelFormFields;
